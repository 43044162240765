import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import warehouseStoreModule from '../warehouseStoreModule';

export default function useWarehouseDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'warehouse';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, warehouseStoreModule);
  }

  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const warehouseId = computed(() => props.warehouseId);

  const fetchDetailData = () => {
    if (warehouseId.value) {
      isLoading.value = true;
      store.dispatch('warehouse/getWarehouseDetail', warehouseId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  watch(warehouseId, val => {

  });

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchDetailData,
    onOpen,
    isLoading,
  };
}
